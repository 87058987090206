import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  isDropdownOpen: boolean;

  constructor(private router: Router) {
    this.isDropdownOpen = false;
  }

  ngOnInit(): void {
    window.onscroll = () => this.scrollFunction();
  }

  openMenu(): void {
    if (!this.navbarCheckbox) {
      document.getElementById("grey-bg").style.backgroundColor = "#0004";
      document.getElementById("grey-bg").style.visibility = "visible";
    } else {
      document.getElementById("grey-bg").style.backgroundColor = "inherit";
      document.getElementById("grey-bg").style.visibility = "hidden";
    }
  }

  closeMenu(): void {
    this.navbarCheckbox = false;
    document.getElementById("grey-bg").style.backgroundColor = "inherit";
    document.getElementById("grey-bg").style.visibility = "hidden";
  }

  navbarCheckbox = false;

  navigate(route: string): void {
    this.navbarCheckbox = false;
    this.isDropdownOpen = false;
    document.getElementById("grey-bg").style.backgroundColor = "inherit";
    document.getElementById("grey-bg").style.visibility = "hidden";
    this.router.navigate(["/" + route]);
  }

  scrollFunction() {
    if (window.innerWidth > 991) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("navbar").style.backgroundColor = "#fff";
        document.getElementById("navbar").style.boxShadow =
          "0 10px 20px #56565622";
      } else {
        document.getElementById("navbar").style.backgroundColor = "inherit";
        document.getElementById("navbar").style.boxShadow = "inherit";
      }
    }
  }
}
