import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-liposzomalis-technologia',
  templateUrl: './liposzomalis-technologia.component.html',
  styleUrls: ['./liposzomalis-technologia.component.scss']
})
export class LiposzomalisTechnologiaComponent implements OnInit {

  boxes = [
    {
      header: 'A liposzómás technológia',
      text: `<p>A liposzómás technológia a legújabb technológiai áttörések egyike, melyet az orvos-kutatók a fontos hatóanyagok megfelelő szervekhez történő eljuttatására alkalmaznak. Ez a technológia ugyanis célzott szállítást tesz lehetővé a szervezeten belül.</p>`,
      imgUrl: '/assets/liposzomas-technologia/box-1.webp',
      direction: '1'
    },
    {
      header: 'A liposzómás glutation',
      text: `<p>A liposzómás glutation nem más, mint a glutation és természetes anyagok, ún. foszfolipidek okos kombinációja. A gyártási folyamat során ezek a foszfolipidek természetes burkot alakítanak ki minden egyes glutation molekula körül. Így alakulnak ki a liposzómák.</p>
      <p>A liposzómák a természetben sem ismeretlenek: az anyatejben is ilyen foszfolipid burok veszi körül a tápanyagokat, hogy azokat a babák szervezete a lehető legjobban tudja hasznosítani.</p>
      `,
      imgUrl: '/assets/liposzomas-technologia/box-2.webp',
      direction: '2'
    },
    {
      header: 'A hatóanyagot körbevevő burok',
      text: `
      <ul>
      <li>védőréteget nyújt a hatóanyag számára az emésztési folyamat során,</li>
      <li>lehetővé teszi a kiváló hatékonyságú felszívódást és hasznosulást,</li>
      <li>hozzájárul a sejtek megfelelő működésének támogatásához.</li>
      </ul>
      <p>A kiváló hasznosulás további előnye, hogy csökkenthető a dózis, mert a
      hatóanyag eljut oda, ahol ténylegesen hatnia kell.</p>`,
      imgUrl: '/assets/liposzomas-technologia/box-3.webp',
      direction: '1'
    },
  ]

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Liposzómás technológia - Glutabalance");
    this.metaTagService.updateTag({ name: "description", content: "Mi az a liposzómás technológia? A liposzómás technológia a legújabb technológiai áttörések egyike, tudjon meg többet előnyeiről!" });
  }
}
