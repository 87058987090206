import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EmailsService } from "src/app/services/emails.service";
import { FeedbacksService } from "src/app/services/feedbacks.service";
import { Meta, Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ContactModel } from "src/app/models/contact.model";
import { FeedbackModel } from "src/app/models/feedback.model";

@Component({
  selector: "app-kapcsolat",
  templateUrl: "./kapcsolat.component.html",
  styleUrls: ["./kapcsolat.component.scss"],
})
export class KapcsolatComponent implements OnInit, AfterViewInit {
  @ViewChild("velemeny") velemeny: ElementRef;

  contactFormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    subject: new FormControl("", []),
    message: new FormControl("", []),
    privacyPolicy: new FormControl(false, [Validators.required]),
  });

  isLoadingContact: boolean;
  isContactError: boolean;

  feedbackFormGroup = new FormGroup({
    feedbackName: new FormControl("", [Validators.required]),
    rate: new FormControl(null, [Validators.required]),
    feedbackMessage: new FormControl("", []),
    feedbackPrivacyPolicy: new FormControl(null, [Validators.required]),
  });

  isLoadingFeedback: boolean;
  isFeedbackError: boolean;

  rateStars = Array(5)
    .fill(0)
    .map((x, i) => i);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailsService: EmailsService,
    private feedbacksService: FeedbacksService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.isLoadingContact = false;
    this.isContactError = false;
    this.isLoadingFeedback = false;
    this.isFeedbackError = false;
  }

  ngOnInit() {
    this.titleService.setTitle("Kapcsolat - Glutabalance");
    this.metaTagService.updateTag({
      name: "description",
      content:
        "Ha kérdése van a Glutabalance termékeivel kapcsolatban, vegye fel velünk a kapcsolatot elérhetőségeinken vagy az űrlap kitöltésével.",
    });
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        if (fragment === "velemeny") {
          setTimeout(() => {
            let domElement = this.velemeny.nativeElement as HTMLElement;
            domElement.scrollIntoView();
          }, 10);
        }
      }
    });
  }

  sentEmail(): void {
    if (this.contactFormGroup.invalid) {
      this.contactFormGroup.markAllAsTouched();
      this.contactFormGroup.updateValueAndValidity();
      return;
    }

    this.isLoadingContact = true;
    this.isContactError = false;

    const contact: ContactModel = {
      name: this.contactFormGroup.controls["name"].value,
      email: this.contactFormGroup.controls["email"].value,
      subject: this.contactFormGroup.controls["subject"].value,
      message: this.contactFormGroup.controls["message"].value,
      privacyPolicy: this.contactFormGroup.controls["privacyPolicy"].value,
    };

    this.emailsService
      .createContact(contact)
      .then((res: any) => {
        if (res.status === "OK") {
          this.sendingContactToGTM("contact-form-1", contact);
          this.isLoadingContact = false;
          this.router.navigate(["/koszonjuk-a-levelet"]);
        } else {
          this.isLoadingContact = false;
          this.isContactError = true;
        }
      })
      .catch((err: any) => {
        this.isLoadingContact = false;
        this.isContactError = true;
      });
  }

  sentFeedback(): void {
    if (this.feedbackFormGroup.invalid) {
      this.feedbackFormGroup.markAllAsTouched();
      this.feedbackFormGroup.updateValueAndValidity();
      return;
    }

    this.isLoadingFeedback = true;
    this.isFeedbackError = false;

    const feedback: FeedbackModel = {
      name: this.feedbackFormGroup.controls["feedbackName"].value,
      rate: this.feedbackFormGroup.controls["rate"].value,
      message: this.feedbackFormGroup.controls["feedbackMessage"].value,
      privacyPolicy:
        this.feedbackFormGroup.controls["feedbackPrivacyPolicy"].value,
    };

    this.feedbacksService
      .createFeedback(feedback)
      .then((res: any) => {
        if (res.status === "OK") {
          this.sendingFeedbackToGTM("feedback-form-1", feedback);
          this.isLoadingFeedback = false;
          this.router.navigate(["/koszonjuk-a-velemenyet"]);
        } else {
          this.isLoadingFeedback = false;
          this.isFeedbackError = true;
        }
      })
      .catch((err: any) => {
        this.isLoadingFeedback = false;
        this.isFeedbackError = true;
      });
  }

  sendingContactToGTM(formId: string, contact: ContactModel): void {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: "formSubmitSuccess",
      form: {
        formId: formId,
        name: contact.name,
        email: contact.email,
        subject: contact.subject,
        message: contact.message,
        privacyPolicy: contact.privacyPolicy,
      },
    });
  }

  sendingFeedbackToGTM(formId: string, feedback: FeedbackModel): void {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: "formSubmitSuccess",
      form: {
        formId: formId,
        name: feedback.name,
        rate: feedback.rate,
        message: feedback.message,
        privacyPolicy: feedback.privacyPolicy,
      },
    });
  }
}
