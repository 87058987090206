import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-termekeink',
  templateUrl: './termekeink.component.html',
  styleUrls: ['./termekeink.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermekeinkComponent implements OnInit {

  @Input() texts;

  constructor() { }

  ngOnInit(): void {
  }

}
