import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContactModel } from "../models/contact.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EmailsService {
  constructor(private http: HttpClient) {}

  createContact(contact: ContactModel) {
    return this.http
      .post(`${environment.backendUrl}/contact`, contact)
      .toPromise();
  }
}
