import { Component, OnInit, Input, HostListener } from "@angular/core";

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent implements OnInit {
  @Input() bgUrl: string;
  @Input() bgUrlM: string;
  @Input() header: string;
  @Input() text: string;

  imgUrl: string;

  constructor() {}

  ngOnInit(): void {
    this.imgUrl = window.innerWidth > 767 ? this.bgUrl : this.bgUrlM;
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.imgUrl = window.innerWidth > 767 ? this.bgUrl : this.bgUrlM;
  }
}
