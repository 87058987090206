// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD_-RVatWrWSu1UT7G3j7sIdJfGne5qRQc",
    authDomain: "glutabalance.firebaseapp.com",
    projectId: "glutabalance",
    storageBucket: "glutabalance.appspot.com",
    messagingSenderId: "1012574231080",
    appId: "1:1012574231080:web:e5843f846106a04b03b31d",
  },
  backendUrl: "https://pikk-be.herokuapp.com/glutabalance",
};
