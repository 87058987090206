<app-hero
  [bgUrl]="'/assets/mi-a-pikkelysomor/header-2.webp'"
  [bgUrlM]="'/assets/mi-a-pikkelysomor/header-m-2.webp'"
  [header]="'Mi a pikkelysömör?'"
  [text]="
    'Nem is gondolnánk, de a pikkelysömör, orvosi nyelven psoriasis a magyar lakosság 2%-t érinti.'
  "
></app-hero>
<section id="mi-a-pikkelysomor">
  <div class="container">
    <div class="betegseg">
      <h1>1. Betegség</h1>
      <div class="betegseg-terkep">
        <div class="terkep-text">
          <p>
            A pikkelysömör egy autoimmun bőrbetegség, amelyben a lakosság 2%-a
            érintett.
          </p>
        </div>
        <div class="terkep-img">
          <img src="/assets/mi-a-pikkelysomor/terkep1.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="testen-valo-megjelenes">
      <h1>2. Testen való megjelenés</h1>
      <p>
        Leggyakrabban a hajas fejbőr, végtagok (térd, könyök), körmök területein
        jelentkezik a tünet.
      </p>

      <div class="pikkely-boxes">
        <div class="pikkely-box">
          <h2>Leggyakoribb tünetek</h2>
          <div class="tunet">
            <div class="tunet-inner">
              <div class="tunet-img">
                <img src="/assets/mi-a-pikkelysomor/voros-bor.webp" alt="" />
              </div>
              <h3>Vastag, vörös bőr</h3>
            </div>
          </div>
          <div class="tunet">
            <div class="tunet-inner">
              <div class="tunet-img">
                <img src="/assets/mi-a-pikkelysomor/hamlas.webp" alt="" />
              </div>
              <h3>Hámlás</h3>
            </div>
          </div>
          <div class="tunet">
            <div class="tunet-inner">
              <div class="tunet-img">
                <img src="/assets/mi-a-pikkelysomor/viszketes.webp" alt="" />
              </div>
              <h3>Viszketés</h3>
            </div>
          </div>
          <div class="tunet">
            <div class="tunet-inner">
              <div class="tunet-img">
                <img src="/assets/mi-a-pikkelysomor/fajdalom.webp" alt="" />
              </div>
              <h3>Fájdalom</h3>
            </div>
          </div>
        </div>
        <div class="pikkely-box">
          <h2>Társbetegségek</h2>
          <div class="tunet">
            <div class="tunet-inner">
              <div class="tunet-img">
                <img src="/assets/mi-a-pikkelysomor/depresszio.webp" alt="" />
              </div>
              <h3>Depresszió</h3>
            </div>
          </div>
          <div class="tunet">
            <div class="tunet-inner">
              <div class="tunet-img">
                <img
                  src="/assets/mi-a-pikkelysomor/cukorbetegseg.webp"
                  alt=""
                />
              </div>
              <h3>Cukorbetegség</h3>
            </div>
          </div>
          <div class="tunet">
            <div class="tunet-inner">
              <div class="tunet-img">
                <img
                  src="/assets/mi-a-pikkelysomor/izuleti-gyulladas.webp"
                  alt=""
                />
              </div>
              <h3>Pszoriázisos izületi gyulladások</h3>
            </div>
          </div>
          <div class="tunet">
            <div class="tunet-inner">
              <div class="tunet-img">
                <img src="/assets/mi-a-pikkelysomor/szivbetegseg.webp" alt="" />
              </div>
              <h3>Szívbetegség</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tunetek">
      <h1>3. Tünetek</h1>
      <p>Fogyasztói kutatás alapján a tünetek leggyakoribb megjelenései:</p>
      <div class="kep3">
        <div class="kep">
          <img src="/assets/mi-a-pikkelysomor/vegtagokon.webp" alt="" />
          <span class="szam">30%</span>
          <span>Végtagokon</span>
        </div>
        <div class="kep">
          <img src="/assets/mi-a-pikkelysomor/fejboron.webp" alt="" />
          <span class="szam">44%</span>
          <span>Arc és hajas fejbőrön</span>
        </div>
        <div class="kep">
          <img src="/assets/mi-a-pikkelysomor/testreszeken.webp" alt="" />
          <span class="szam">21%</span>
          <span>Törzs, mellkas és egyéb testrészeken</span>
        </div>
      </div>
      <p>
        A tünetek súlyossága és típusa eltérhetnek attól függően, mekkora
        kiterjedtségben jelenik meg a bőr felületén.
      </p>
      <div class="kep3-2">
        <div class="kep">
          <img src="/assets/mi-a-pikkelysomor/enyhe.webp" alt="" />
          <span class="cim">Enyhe</span>
        </div>
        <div class="kep">
          <img src="/assets/mi-a-pikkelysomor/mersekelt.webp" alt="" />
          <span class="cim">Mérsékelt</span>
        </div>
        <div class="kep">
          <img src="/assets/mi-a-pikkelysomor/sulyos.webp" alt="" />
          <span class="cim">Súlyos</span>
        </div>
      </div>
    </div>
    <div class="szakaszok">
      <h1>4. Szakaszok</h1>
      <div class="szakaszok-abra">
        <div class="szakaszok-text">
          <p>
            A betegségnek két szakasza ismert: akut és nyugvó szakasz. Az akut
            szakaszban a tünetek fellángolnak ,míg a nyugvó szakaszban akár
            teljesen vissza is húzódnak.
          </p>
        </div>
        <div class="szakaszok-img">
          <img src="/assets/mi-a-pikkelysomor/szakaszok.webp" alt="" />
        </div>
      </div>
      <div class="pikkely-boxes">
        <div class="pikkely-box">
          <h2>Társult betegségek</h2>
          <div class="szakasz">
            <span class="szam">30%</span>
            <span class="text"
              >A betegek <strong>30%</strong>-ának párosul a
              <strong
                >pikkelysömöre ízületi gyulladással - psoriasis
                arthritis-szel.</strong
              ></span
            >
          </div>
          <div class="szakasz">
            <span class="szam">48%</span>
            <span class="text"
              >A súlyos psoriasis-sal szenvedők <strong>46%</strong>-kal nagyobb
              eséllyel diagnosztizáltak
              <strong>2-es típusú diabétesszel.</strong></span
            >
          </div>
          <div class="szakasz">
            <span class="szam">58%</span>
            <span class="text"
              >A súlyos psoriasis-sal szenvedők <strong>58%</strong>-kal nagyobb
              eséllyel diagnosztizáltak
              <strong>kardiovaszkuláris</strong> betegséggel.</span
            >
          </div>
          <div class="szakasz">
            <span class="szam">25%</span>
            <span class="text"
              >A betegség a psoriasis-sal küzdők <strong>25%</strong>-nak
              <strong>életminőségét rontja</strong> (stressz, depresszió,
              stigmatizáltság érzés).</span
            >
          </div>
        </div>
        <div class="pikkely-box">
          <h2>Beteg megélés</h2>
          <div class="szakasz">
            <span class="szam">89%</span>
            <span class="text"
              >A pikkelysömörben szenvedők <strong>89%</strong>-a
              <strong>szégyenkezik</strong> betegsége miatt.</span
            >
          </div>
          <div class="szakasz">
            <span class="szam">42%</span>
            <span class="text"
              ><strong>42%</strong>-a a pikkelysömörben szenvedőknek
              <strong>önbizalom hiányban</strong> szenved.</span
            >
          </div>
          <div class="szakasz">
            <span class="szam">62%</span>
            <span class="text"
              >A pikkelysömörös betegek <strong>62%</strong>-nak van
              <strong>depressziós</strong> tünete.</span
            >
          </div>
          <div class="szakasz">
            <span class="szam">44%</span>
            <span class="text"
              >A psoriasis-ban szenvedők <strong>44%</strong>-a
              <strong>küzd nehézségekkel</strong> a munkájában betegsége
              miatt.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="footer">
  <div class="footer-top">
    <div class="container">
      <small
        >A Glutabalance Psorio Forte és a Glutabalance Psorio Caps
        étrend-kiegészítő termékek.<br />Étrend-kiegészítő, nem helyettesíti a
        vegyes étrendet és az egészséges életmódot.</small
      >
      <div class="action-box">
        <h3>Kipróbálom a Glutabalance termékeit!</h3>
        <a
          [routerLink]="['/vasarlasi-informaciok']"
          routerLinkActive="router-link-active"
          >Tovább <i class="icon-chevron-right" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</section>
