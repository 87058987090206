import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-vasarlasi-informaciok',
  templateUrl: './vasarlasi-informaciok.component.html',
  styleUrls: ['./vasarlasi-informaciok.component.scss']
})
export class VasarlasiInformaciokComponent implements OnInit {

  gluta1 = {
    url: '',
    size: '150ml',
    quantity: 1,
  };

  gluta2 = {
    url: '',
    size: '30db',
    quantity: 1,
  };

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Vásárlási információk - Glutabalance");
    this.metaTagService.updateTag({ name: "description", content: "Vásárolja meg egyszerűen, egy kattintással a Glutabalance termékeit, amelyek hatékony segítséget nyújtanak pikkelysömör kezelés esetén is." });
  }

  toCart(product: number): void {
    if (product === 1) {
      console.log(this.gluta1);
      
    }
  }

}
