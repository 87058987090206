<section id="kapcsolat">
  <div class="container">
    <h1>Kapcsolat</h1>
    <p>
      Kérdése, észrevétele van termékünkkel kapcsolatban?<br />Forduljon hozzánk
      bizalommal az alábbi elérhetőségeken!
    </p>
    <div class="kapcsolat-infok">
      <strong>Telefon:</strong>
      <span>(+36) 1 919 3354</span>
      <strong>Fax:</strong>
      <span>(+36) 1 919 0354</span>
      <strong>E-mail:</strong>
      <span
        ><a href="mailto:info@glutabalance.hu" id="gluta-email"
          >info@glutabalance.hu</a
        ></span
      >
      <strong>Cím:</strong>
      <span
        >PP Management Kft.<br />1052 Budapest, Petőfi S. u.<br />11.
        4/20.</span
      >
    </div>
    <div class="form">
      <h2>Lépjen velünk kapcsolatba!</h2>
      <form
        [formGroup]="contactFormGroup"
        (ngSubmit)="sentEmail()"
        id="contact-form-1"
      >
        <div class="form-group">
          <label for="name">Név *</label>
          <input
            type="text"
            id="name"
            name="name"
            formControlName="name"
            required=""
          />
          <span
            class="error"
            [class.show]="
              contactFormGroup.controls['name'].hasError('required') &&
              (contactFormGroup.controls['name'].dirty ||
                contactFormGroup.controls['name'].touched)
            "
          >
            {{ "Kötelező mező!" }}
          </span>
        </div>
        <div class="form-group">
          <label for="email">E-mail cím *</label>
          <input
            type="email"
            id="email"
            name="email"
            formControlName="email"
            required
          />
          <span
            class="error"
            [class.show]="
              contactFormGroup.controls['email'].hasError('required') &&
              (contactFormGroup.controls['email'].dirty ||
                contactFormGroup.controls['email'].touched)
            "
          >
            {{ "Kötelező mező!" }}
          </span>
          <span
            class="error"
            [class.show]="
              contactFormGroup.controls['email'].hasError('email') &&
              (contactFormGroup.controls['email'].dirty ||
                contactFormGroup.controls['email'].touched)
            "
          >
            {{ "Nem megfelelő formátum!" }}
          </span>
        </div>
        <div class="form-group">
          <label for="subject">Tárgy</label>
          <input
            type="text"
            id="subject"
            name="subject"
            formControlName="subject"
          />
        </div>
        <div class="form-group">
          <label for="message">Üzenet</label>
          <textarea
            name="message"
            id="message"
            rows="4"
            name="message"
            formControlName="message"
          ></textarea>
        </div>
        <div class="form-group">
          <input
            type="checkbox"
            id="privacyPolicy"
            name="privacyPolicy"
            formControlName="privacyPolicy"
            required
          />
          <label for="privacyPolicy" class="privacyPolicyLabel">
            Kijelentem, hogy az
            <a
              href="/assets/adatkezelesi-tajekoztato.pdf"
              target="_blank"
              style="text-decoration: underline; color: #3d1e58"
              >Adatkezelési tájékoztatóban</a
            >
            foglaltakat teljes körűen megismertem és megértettem, és az abban
            foglaltak szerint önkéntesen hozzájárulok a személyes adataim
            kezeléséhez.</label
          ><br />
          <span
            class="error"
            [class.show]="
              contactFormGroup.controls['privacyPolicy'].hasError('required') &&
              (contactFormGroup.controls['privacyPolicy'].dirty ||
                contactFormGroup.controls['privacyPolicy'].touched)
            "
          >
            {{ "Kötelező mező!" }}
          </span>
        </div>
        <button type="submit" class="submit" [disabled]="isLoadingContact">
          <ng-container>
            Küldés <i class="icon-paper-plane" *ngIf="!isLoadingContact"></i
            ><span *ngIf="isLoadingContact">...</span>
          </ng-container>
          <ng-container *ngIf="isContactError">
            A küldés nem sikerült <i class="icon-circle-xmark"></i>
          </ng-container>
        </button>
      </form>
    </div>
    <div class="velemeny" id="velemeny" #velemeny>
      <h2>Ossza meg velünk véleményét a termékről!</h2>
      <form
        [formGroup]="feedbackFormGroup"
        (ngSubmit)="sentFeedback()"
        id="feedback-form-1"
      >
        <div class="form-group">
          <label for="feedbackName">Név *</label>
          <input
            type="text"
            id="feedbackName"
            name="name"
            formControlName="feedbackName"
            required
          />
          <span
            class="error"
            [class.show]="
              feedbackFormGroup.controls['feedbackName'].hasError('required') &&
              (feedbackFormGroup.controls['feedbackName'].dirty ||
                feedbackFormGroup.controls['feedbackName'].touched)
            "
          >
            {{ "Kötelező mező!" }}
          </span>
        </div>
        <div class="form-group">
          <label for="rate">Értékelés *</label>
          <ng-container *ngFor="let star of rateStars; let i = index">
            <i
              class="icon-star-full"
              [class.coloredStar]="i < feedbackFormGroup.controls['rate'].value"
              (click)="feedbackFormGroup.controls['rate'].setValue(i + 1)"
            ></i>
          </ng-container>
          <span
            class="error"
            [class.show]="
              feedbackFormGroup.controls['rate'].hasError('required') &&
              (feedbackFormGroup.controls['rate'].dirty ||
                feedbackFormGroup.controls['rate'].touched)
            "
          >
            {{ "Kötelező mező!" }}
          </span>
        </div>
        <div class="form-group">
          <label for="feedbackMessage">Szöveges értékelés</label>
          <textarea
            name="feedbackMessage"
            id="feedbackMessage"
            rows="4"
            name="feedbackMessage"
            formControlName="feedbackMessage"
          ></textarea>
        </div>
        <div class="form-group">
          <input
            type="checkbox"
            id="feedbackPrivacyPolicy"
            name="feedbackPrivacyPolicy"
            formControlName="feedbackPrivacyPolicy"
          />
          <label for="feedbackPrivacyPolicy" class="privacyPolicyLabel">
            Kijelentem, hogy az
            <a
              href="/assets/Adatkezelési tájékoztató_glutabalance.hu.pdf"
              target="_blank"
              style="text-decoration: underline; color: #3d1e58"
              >Adatkezelési tájékoztatóban</a
            >
            foglaltakat teljes körűen megismertem és megértettem, és az abban
            foglaltak szerint önkéntesen hozzájárulok a személyes adataim
            kezeléséhez.</label
          ><br />
          <span
            class="error"
            [class.show]="
              feedbackFormGroup.controls['feedbackPrivacyPolicy'].hasError(
                'required'
              ) &&
              (feedbackFormGroup.controls['feedbackPrivacyPolicy'].dirty ||
                feedbackFormGroup.controls['feedbackPrivacyPolicy'].touched)
            "
          >
            {{ "Kötelező mező!" }}
          </span>
        </div>
        <button type="submit" class="submit" [disabled]="isLoadingFeedback">
          <ng-container *ngIf="!isFeedbackError">
            Küldés <i class="icon-paper-plane" *ngIf="!isLoadingFeedback"></i
            ><span *ngIf="isLoadingFeedback">...</span>
          </ng-container>
          <ng-container *ngIf="isFeedbackError">
            A küldés nem sikerült <i class="icon-circle-xmark"></i>
          </ng-container>
        </button>
      </form>
    </div>
  </div>
</section>

<section id="footer">
  <div class="footer-top">
    <div class="container">
      <small
        >A Glutabalance Psorio Forte és a Glutabalance Psorio Caps
        étrend-kiegészítő termékek.<br />Étrend-kiegészítő, nem helyettesíti a
        vegyes étrendet és az egészséges életmódot.</small
      >
      <div class="action-box">
        <h3>Kipróbálom a Glutabalance termékeit!</h3>
        <a
          [routerLink]="['/vasarlasi-informaciok']"
          routerLinkActive="router-link-active"
          >Tovább <i class="icon-chevron-right" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</section>
