import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-mi-a-pikkelysomor',
  templateUrl: './mi-a-pikkelysomor.component.html',
  styleUrls: ['./mi-a-pikkelysomor.component.scss']
})
export class MiAPikkelysomorComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Mi a pikkelysömör? - Glutabalance");
    this.metaTagService.updateTag({ name: "description", content: "Tudjon meg többet a pikkelysömör tüneteiről, kialakulásáról. A Glutabalance termékei hatóanyagai révén segítenek a száraz és hámló bőr tüneteinek kezelésében." });
  }

}
