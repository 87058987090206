<section id="termekeink-header">
  <div class="bg-img">
    <div class="bg-white">
      <div class="container">
        <div class="blur">
          <div class="text">
            <h1 [innerHTML]="title"></h1>
            <h3 [innerHTML]="subtitle"></h3>
            <ul>
              <li *ngFor="let item of list; let i = index">
                <strong *ngIf="i < 3">
                  <span [innerHTML]="item"></span>
                </strong>
                <span *ngIf="i >= 3" [innerHTML]="item"></span>
              </li>
            </ul>
          </div>
          <div class="termek-img">
            <img [src]="imgUrl" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="elonyok">
    <div class="container">
        <div class="elonyok">
            <div class="elonyok-box">
              <img src="/assets/termekeink/vegan.webp" alt="">
              <span>Vegán termék</span>
            </div>
            <div class="elonyok-box">
                <img src="/assets/termekeink/gmo-mentes.webp" alt="">
                <span>GMO-mentes</span>
            </div>
            <div class="elonyok-box">
                <img src="/assets/termekeink/cukormentes.webp" alt="">
                <span>Cukormentes</span>
            </div>
        </div>
    </div>
</section>