import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { FeedbackModel } from "../models/feedback.model";

@Injectable({
  providedIn: "root",
})
export class FeedbacksService {
  constructor(private http: HttpClient) {}

  createFeedback(feedback: FeedbackModel) {
    return this.http
      .post(`${environment.backendUrl}/feedback`, feedback)
      .toPromise();
  }
}
