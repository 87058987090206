<section id="hero">
  <div class="bg-img" [ngStyle]="{ 'background-image': 'url(' + imgUrl + ')' }">
    <div class="bg-gradient">
      <div class="text">
        <div class="text-inner">
          <h1 [innerHTML]="header"></h1>
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
