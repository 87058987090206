import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-glutabalance-hatasai',
  templateUrl: './glutabalance-hatasai.component.html',
  styleUrls: ['./glutabalance-hatasai.component.scss']
})
export class GlutabalanceHatasaiComponent implements OnInit {

  boxes = [
    {
      header: 'Mi a glutation?',
      text: `<p>A <strong>glutation</strong> egy három alkotóelemből – ciszteinből, glutaminsavból és glicinből – álló, <strong>antioxidáns aktivitással</strong> rendelkező fehérje. A glutation a méregtelenítésben, vagyis a szervezetben felhalmozódó, azt károsító anyagok eltávolításában is részt vesz. Az ilyen folyamatokban a szervezetidegen anyagokból vízben jól oldódó vegyületek képződnek, amelyek könnyen távoznak a sejtekből.</p>
      <p>A glutationnak mindezen túl szerepe van az <strong>immunműködésben</strong>, valamint a kóros működésű sejtek szervezetből történő eltávolításában is.</p>`,
      imgUrl: '/assets/a-glutabalance-hatasai/box-1.webp',
      direction: '1',
      toBottom: false
    },
    {
      header: 'Miért fontos a szervezetben a glutation?',
      text: `<p>A glutation alapesetben nagy mennyiségben van jelen a sejtjeinkben, mivel szervezetünk képes azt előállítani. Ugyanakkor előfordulhat, hogy a sejtjeinkben glutationhiány alakul ki (pl. sérült enzimműködés esetén). A tartós glutationhiány a sejtek számára oxidatív stresszt jelent, ilyenkor a szervezet <strong>méregtelenítő képessége</strong> és az <strong>immunrendszer</strong> működése kárt szenved.</p>
      <p>Pszoriázisos betegekkel végzett vizsgálatokban arra figyeltek fel, hogy a náluk fellépő oxidatív stressz alacsony glutationszinttel párosul. <strong>Tehát a glutation mennyisége és a tünetek intenzitása közötti össszefüggés többszörösen igazolt.</strong></p>
      `,
      imgUrl: '/assets/a-glutabalance-hatasai/box-2.webp',
      direction: '2',
      toBottom: true
    },
    {
      header: 'A Glutabalance egyéb jótékony hatóanyagai a pikkelysömör megelőzésére',
      text: `<p>A máriatövis támogatja a máj egészségét és hozzájárul annak méregtelenítő potenciáljához, melyen keresztül segíti a test megtisztulását. Pikkelysömörben azért tartják értékesnek, mert elősegíti az endotoxinok eltávolítását a májból, gátolja CAMP foszfodiészterázt és a leukotriénszintézist. A CAMP és a leukotriének szintje pszoriázsiban szenvedőknél emelkedett, és ezek koncentrációjának normalizálása hozzájárulhat az állapot javulásához.</p>
      <ul>
      <li>A lutein javítja a bőr hidratáltságát.</li>
      <li>A B5-vitamin segíti a bőr hidratáltságát és küzd a hámlás és a bőr szárazság ellen.</li>
      <li>A D-vitamin hozzájárul az immunrendszer normál működéséhez.</li>
      <li>A C-vitamin hozzájárul az immunrendszer normál működéséhez.</li>
      <li>A feketekömény (Nigella sativa) gyulladáscsökkentő olaja sokoldalúan támogatja a glutation összetett hatását.</li>
      </ul>
      `,
      imgUrl: '/assets/a-glutabalance-hatasai/box-3.webp',
      direction: '1',
      toBottom: true
    },
  ]

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Hogyan hatnak a Glutabalance termékei? - Glutabalance");
    this.metaTagService.updateTag({ name: "description", content: "Tudjon meg többet a glutation hatóanyagról, amely erős antioxidánsként fejti ki hatását a Glutabalance termékeiben." });
  }

}
