<section id="testimonial">
  <div class="container">
    <h1>Vélemények</h1>
    <div class="testimonials">
      <div class="testimonial" *ngFor="let testimonial of testimonials">
        <div class="img-box">
          <img [src]="testimonial.imgUrl" alt="" />
        </div>
        <div class="text-box">
          <div class="text-box-inner">
            <q class="message">
              {{ testimonial.message }}
            </q>
            <hr />
            <div class="name-age">
              <span>{{ testimonial.name }}, {{ testimonial.age }}</span>
            </div>
            <div class="address">
              <span>{{ testimonial.address }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
