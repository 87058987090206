import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Code } from '../models/code.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CodesService {

  collectionName: string;

  constructor(
    private firestore: AngularFirestore
  ) {
    this.collectionName = 'codes3';
  }

  // createCode(code: Code) {
  //   return this.firestore.collection(this.collectionName).doc(code.code).set(code);
  // }

  getCodes() {
    return this.firestore.collection(this.collectionName).snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as Code;
        data.id = a.payload.doc.id;
        return data;
      });
    }));
  }

  updateCode(code: Code) {
    return this.firestore.collection(this.collectionName).doc(code.id).set(code);
  }
}
