<app-hero
  [bgUrl]="'/assets/liposzomas-technologia/header.webp'"
  [bgUrlM]="'/assets/liposzomas-technologia/header-m.webp'"
  [header]="'A liposzómás technológia'"
  [text]="
    'Legújabb technológiai áttörések egyike a hatékonyabb felszívódásért.'
  "
></app-hero>
<ng-container *ngFor="let box of boxes">
  <app-box
    [header]="box.header"
    [text]="box.text"
    [imgUrl]="box.imgUrl"
    [direction]="box.direction"
  >
  </app-box>
</ng-container>

<section id="footer">
  <div class="footer-top">
    <div class="container">
      <small
        >A Glutabalance Psorio Forte és a Glutabalance Psorio Caps
        étrend-kiegészítő termékek.<br />Étrend-kiegészítő, nem helyettesíti a
        vegyes étrendet és az egészséges életmódot.</small
      >
      <div class="action-box">
        <h3>Kipróbálom a Glutabalance termékeit!</h3>
        <a
          [routerLink]="['/vasarlasi-informaciok']"
          routerLinkActive="router-link-active"
          >Tovább <i class="icon-chevron-right" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</section>
