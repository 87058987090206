import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { HttpClientModule } from "@angular/common/http";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { IvyCarouselModule } from "angular-responsive-carousel";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { TermekeinkComponent } from "./pages/termekeink/termekeink.component";
import { MiAPikkelysomorComponent } from "./pages/mi-a-pikkelysomor/mi-a-pikkelysomor.component";
import { GlutabalanceHatasaiComponent } from "./pages/glutabalance-hatasai/glutabalance-hatasai.component";
import { LiposzomalisTechnologiaComponent } from "./pages/liposzomalis-technologia/liposzomalis-technologia.component";
import { VasarlasiInformaciokComponent } from "./pages/vasarlasi-informaciok/vasarlasi-informaciok.component";
import { KapcsolatComponent } from "./pages/kapcsolat/kapcsolat.component";
import { FooldalComponent } from "./pages/fooldal/fooldal.component";
import { TermekeinkHeaderComponent } from "./pages/termekeink/termekeink-header/termekeink-header.component";
import { TermekekTabComponent } from "./pages/termekeink/termekek-tab/termekek-tab.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeroComponent } from "./components/hero/hero.component";
import { BoxComponent } from "./components/box/box.component";
import { FooterComponent } from "./components/footer/footer.component";
import { GlutabalancePsorioforteComponent } from "./pages/termekeink/glutabalance-psorioforte/glutabalance-psorioforte.component";
import { GlutabalancePsoriocapsComponent } from "./pages/termekeink/glutabalance-psoriocaps/glutabalance-psoriocaps.component";
import { TermekkiprobalasService } from "./services/termekkiprobalas.service";
import { HttpClient } from "@angular/common/http";
import { EmailsService } from "./services/emails.service";
import { FeedbacksService } from "./services/feedbacks.service";
import { KoszonjukComponent } from "./pages/koszonjuk/koszonjuk.component";
import { TestimonialComponent } from "./pages/testimonial/testimonial.component";
import { MiAzEkcemaComponent } from './pages/mi-az-ekcema/mi-az-ekcema.component';
import { SzarazGyulladtViszketoBorComponent } from './pages/szaraz-gyulladt-viszketo-bor/szaraz-gyulladt-viszketo-bor.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    TermekeinkComponent,
    MiAPikkelysomorComponent,
    GlutabalanceHatasaiComponent,
    LiposzomalisTechnologiaComponent,
    VasarlasiInformaciokComponent,
    KapcsolatComponent,
    FooldalComponent,
    TermekeinkHeaderComponent,
    TermekekTabComponent,
    HeroComponent,
    BoxComponent,
    FooterComponent,
    GlutabalancePsorioforteComponent,
    GlutabalancePsoriocapsComponent,
    KoszonjukComponent,
    TestimonialComponent,
    MiAzEkcemaComponent,
    SzarazGyulladtViszketoBorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    IvyCarouselModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    TermekkiprobalasService,
    EmailsService,
    FeedbacksService,
    HttpClient,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
