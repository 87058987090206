import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-koszonjuk",
  templateUrl: "./koszonjuk.component.html",
  styleUrls: ["./koszonjuk.component.scss"],
})
export class KoszonjukComponent implements OnInit {
  title = "";
  description = "";

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.router.url.toString() === "/koszonjuk-a-levelet") {
      this.title = "Köszönjük a levelét!";
      this.description =
        "Köszönjük szépen a levelét, kollégánk hamarosan felveszi Önnel a kapcsolatot!";
    } else if (this.router.url.toString() === "/koszonjuk-a-velemenyet") {
      this.title = "Köszönjük a visszajelzését!";
      this.description =
        "Köszönjük szépen, hogy megosztotta velünk véleményét, kollégánk hamarosan feldolgozza a kapott üzenetet!";
    }
  }
}
