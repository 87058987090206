import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-glutabalance-psorioforte',
  templateUrl: './glutabalance-psorioforte.component.html',
  styleUrls: ['./glutabalance-psorioforte.component.scss']
})
export class GlutabalancePsorioforteComponent implements OnInit {

  texts = {
    title: 'Glutabalance Psorio Forte liposzómás glutation tartalmú étrend-kiegészítő folyadék 150 ml',
    subtitle: 'Az egészséges és megfelelően hidratált bőrért.',
    list: [
      'A glutation antioxidáns hozzájárul a test védekezőképességéhez.',
      'A lutein javítja a bőr hidratáltságát.',
      'A B5-vitamin segíti a bőr hidratáltságát és küzd a hámlás és a bőr szárazság ellen.',
      'A D-vitamin hozzájárul az immunrendszer normál működéséhez.',
      'A C-vitamin hozzájárul az immunrendszer normál működéséhez.',
      'A máriatövis támogatja a máj egészségét és hozzájárul annak méregtelenítő potenciáljához, melyen keresztül segíti a test megtisztulását.'
    ],
    imgUrl: '/assets/termekeink/termek1.webp',
    tableTitle: 'Hatóanyagok és összetevők 5 ml-ben',
    tableSubtitle: '5 ml napi adagban',
    table: [
      {
        col1: 'Glutation',
        col2: '250,0',
        col3: 'NA'
      },
      {
        col1: 'C-vitamin',
        col2: '250,0',
        col3: '313'
      },
      {
        col1: 'N-acetil-cisztein',
        col2: '100',
        col3: ''
      },
      {
        col1: 'Glutamin',
        col2: '90',
        col3: ''
      },
      {
        col1: 'Glicin',
        col2: '45',
        col3: ''
      },
      {
        col1: 'B5-vitamin',
        col2: '6,00',
        col3: '100'
      },
      {
        col1: 'Lutein-antiox',
        col2: '5,0',
        col3: 'NA'
      },
      {
        col1: 'Máriatövis mag olaj',
        col2: '3,0',
        col3: 'NA'
      },
      {
        col1: 'Szurokfű tinktúra',
        col2: '1,0',
        col3: 'NA'
      },
      {
        col1: 'Fekete köménymag olaj',
        col2: '1,0',
        col3: 'NA'
      },
      {
        col1: 'D3-vitamin',
        col2: '0,05',
        col3: '1 000'
      },
    ],
    ingredients: [
      'Ioncserélt víz',
      'glicerin',
      'emulgeálószer',
      'szójalecitin',
      'mangó aroma',
      'tartósítószer: kálium-szorbát',
      'édesítőszer: stevia',
    ],
    dosage: [
      {
        imgUrl: '/assets/termekeink/baby.webp',
        text: 'Gyermekeknek 6 éves kor alatt nem adható!'
      },
      {
        imgUrl: '/assets/termekeink/spoon.webp',
        text: 'Napi 1x5ml (egy teáskanál) magában vagy folyadékban elkeverve'
      },
      {
        imgUrl: '/assets/termekeink/shake.webp',
        text: 'Fogyasztás előtt felrázandó!'
      },
      {
        imgUrl: '/assets/termekeink/mark.webp',
        text: 'Az ajánlott napi adagot ne lépje túl!'
      },
    ],
    storage: [
      {
        imgUrl: '/assets/termekeink/no-sun.webp',
        text: 'Fénytől, sugárzó hőtől védett, száraz hűvös helyen tárolandó!'
      },
      {
        imgUrl: '/assets/termekeink/fridge.webp',
        text: 'Felbontás után hűtőszekrényben tárolja és 30 napon belül fogyassza el.'
      },
      {
        imgUrl: '/assets/termekeink/24month.webp',
        text: 'Minőségét megőrzi: 24 hónap.'
      },
    ],
    feedback: [
      {
        name: 'Gipsz Jakab',
        feedbackMsg: 'Nagyon tetszett a termék, gondoltam hagyok is itt egy kis fogyasztói visszajelzést!',
        stars: [1,1,1,1,1]
      },
      {
        name: 'Gipsz Jakab 2',
        feedbackMsg: 'Nekem is nagyon tetszett a termék, ezért én hagyok egy hosszabb feedbacket is, hogy könnyebb legyen tesztelni, hogy ilyen esetekben is szépen jelenik-e meg a szöveg. Remélem működni fog!',
        stars: [1,1,1,0,0]
      },
      {
        name: 'Gipsz Jakab',
        feedbackMsg: 'Nagyon tetszett a termék, gondoltam hagyok is itt egy kis fogyasztói visszajelzést!',
        stars: [1,1,1,1,1]
      },
      {
        name: 'Gipsz Jakab 2',
        feedbackMsg: 'Nekem is nagyon tetszett a termék, ezért én hagyok egy hosszabb feedbacket is, hogy könnyebb legyen tesztelni, hogy ilyen esetekben is szépen jelenik-e meg a szöveg. Remélem működni fog!',
        stars: [1,1,1,0,0]
      },
      {
        name: 'Gipsz Jakab',
        feedbackMsg: 'Nagyon tetszett a termék, gondoltam hagyok is itt egy kis fogyasztói visszajelzést!',
        stars: [1,1,1,1,1]
      },
      {
        name: 'Gipsz Jakab 2',
        feedbackMsg: 'Nekem is nagyon tetszett a termék, ezért én hagyok egy hosszabb feedbacket is, hogy könnyebb legyen tesztelni, hogy ilyen esetekben is szépen jelenik-e meg a szöveg. Remélem működni fog!',
        stars: [1,1,1,0,0]
      },
    ]
  }

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Glutabalance Psorio Forte");
    this.metaTagService.updateTag({ name: "description", content: "Glutabalance Psorio Forte liposzómás glutation tartalmú étrend-kiegészítő folyadék, amely akár pikkelysömör kezelés esetén is alkalmazhatóak napi egyszeri alkalmazással." });
  }

}
