import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-termekeink-header',
  templateUrl: './termekeink-header.component.html',
  styleUrls: ['./termekeink-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermekeinkHeaderComponent implements OnInit {

  @Input() imgUrl: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() list;

  constructor() { }

  ngOnInit(): void {
  }

}
