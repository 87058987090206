<app-termekeink-header
  [title]="texts.title"
  [subtitle]="texts.subtitle"
  [list]="texts.list"
  [imgUrl]="texts.imgUrl"
></app-termekeink-header>
<app-termekek-tab
  [title]="texts.tableTitle"
  [subtitle]="texts.tableSubtitle"
  [table]="texts.table"
  [ingredients]="texts.ingredients"
  [dosage]="texts.dosage"
  [storage]="texts.storage"
  [feedback]="texts.feedback"
></app-termekek-tab>

<section id="footer">
  <div class="footer-top">
    <div class="container">
      <small
        >A Glutabalance Psorio Forte és a Glutabalance Psorio Caps
        étrend-kiegészítő termékek.<br />Étrend-kiegészítő, nem helyettesíti a
        vegyes étrendet és az egészséges életmódot.</small
      >
      <div class="action-box">
        <h3>Kipróbálom a Glutabalance termékeit!</h3>
        <a
          [routerLink]="['/vasarlasi-informaciok']"
          routerLinkActive="router-link-active"
          >Tovább <i class="icon-chevron-right" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</section>
