<section id="vasarlasi-infok-title">
  <div class="container">
    <div class="title">
      <h1>Hol tudom megvásárolni<br />a terméket?</h1>
      <p>
        A Glutabalance termékei étrend-kiegészítő termékek, amelyek hatóanyagai
        révén segítséget nyújtanak a pikkelysömör- vagy ekcéma kezelése esetén
        is.
      </p>
      <p>
        A glutation tartalmának köszönhetően erős antioxidáns hatással bír,
        valamint a termékek máriatövis hatóanyaga segíti a máj megtisztulását a
        méreganyagoktól. A termékek egyéb jótékony hatóanyagai is segítik a bőr
        és az immunrendszer megfelelő működését.
      </p>
      <p><strong>Vásárolja meg a Glutabalance termékeit!</strong></p>
    </div>
  </div>
</section>
<section id="discount">
  <div class="container">
    <div class="discount-box">
      <h2>Aktuális akciók</h2>
      <p>Jelenleg nem található aktuális akció a termékekre.</p>
      <!-- <div class="d-flex">
        <div class="left">
          <table class="discount-table">
            <thead>
              <tr>
                <th>Termék neve</th>
                <th>Gyógyszertár, Patika</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Glutabalance Psorio Forte liposzómás<br />étrend-kiegészítő
                  folyadék
                </td>
                <td>Alma</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="right">
          <img src="/assets/vasarlasi-informaciok/akciok.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</section>
<section id="vasarlasi-infok">
  <div class="container">
    <div class="box-termek termek-1">
      <div class="text">
        <h2>
          Glutabalance Psorio Forte liposzómás<br />étrend-kiegészítő folyadék
        </h2>
        <!-- <div class="form-group">
          <input
            type="radio"
            id="termek1-150ml"
            value="150ml"
            name="termek1"
            [(ngModel)]="gluta1.size"
            [ngModelOptions]="{ standalone: true }"
          />
          <label for="termek1-150ml" class="termek1-150ml-label">
            150 ml - 7 999 Ft</label
          ><br />
        </div>
        <div class="form-group">
          <input
            type="number"
            name="quantity1"
            id="quantity1"
            [(ngModel)]="gluta1.quantity"
          />
          <button class="btn btn-primary" [disabled]="!(gluta1.quantity > 0) || gluta1.size === ''" (click)="toCart(1)">
            Megvásárlom
          </button>
        </div> -->
        <div class="form-group">
          <p>Vásárolja meg most a webshopban!</p>
          <a
            href="https://www.vitalweb.hu/termekek/minden-termek/glutabalance-psorio-forte-folyadek-150-ml-meg-1-doboz-ajandek"
            class="btn btn-primary"
            target="_blank"
          >
            Megvásárlom
            <i class="icon-chevron-right" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="img">
        <img src="/assets/vasarlasi-informaciok/termek1.webp" alt="" />
      </div>
    </div>
    <div class="box-termek termek-2">
      <div class="text">
        <h2>
          Glutabalance Psorio Caps glutation<br />tartalmú étrend-kiegészítő
          kapszula
        </h2>
        <!-- <div class="form-group">
          <input
            type="radio"
            id="termek2-30db"
            value="30db"
            name="termek2"
            [(ngModel)]="gluta2.size"
            [ngModelOptions]="{ standalone: true }"
          />
          <label for="termek2-30db" class="termek2-30db-label">
            30 db - 5 299 Ft</label
          ><br />
        </div>
        <div class="form-group">
          <input
            type="number"
            name="quantity2"
            id="quantity2"
            [(ngModel)]="gluta2.quantity"
          />
          <button class="btn btn-primary" [disabled]="!(gluta2.quantity > 0) || gluta2.size === ''" (click)="toCart(2)">
            Megvásárlom
          </button>
        </div> -->
        <div class="form-group">
          <p>Vásárolja meg most a webshopban!</p>
          <a
            href="https://www.vitalweb.hu/termekek/minden-termek/glutabalance-psorio-caps-kapszula-30-db-meg-1-doboz-ajandek"
            class="btn btn-primary"
            target="_blank"
          >
            Megvásárlom
            <i class="icon-chevron-right" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="img">
        <img src="/assets/vasarlasi-informaciok/termek2.webp" alt="" />
      </div>
    </div>
    <!-- <div class="box-termek akcio">
      <div class="text">
        <h2>Novemberi akciók</h2>
        <div class="overflow-x">
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <th>Termék neve</th>
                <th>Gyógyszertár, patika</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Glutabalance Psorio Forte</th>
                <td>Kulcs patikák</td>
              </tr>
              <tr>
                <th>Glutabalance Psorio Caps</th>
                <td>Kulcs patikák</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="img">
        <img src="/assets/vasarlasi-informaciok/akcio.webp" alt="">
      </div>
    </div> -->
  </div>
</section>
