import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BoxComponent implements OnInit {

  @Input() header: string;
  @Input() text: string;
  @Input() imgUrl: string;
  @Input() direction: string;
  @Input() toBottom: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
