<app-hero
  [bgUrl]="'/assets/a-glutabalance-hatasai/header.webp'"
  [bgUrlM]="'/assets/a-glutabalance-hatasai/header-m.webp'"
  [header]="'Hogyan hatnak a Glutabalance termékei?'"
  [text]="
    'Mi az a glutation és milyen hatása van a szervezetünkre?'
  "
></app-hero>
<ng-container *ngFor="let box of boxes">
  <app-box
    [header]="box.header"
    [text]="box.text"
    [imgUrl]="box.imgUrl"
    [direction]="box.direction"
    [toBottom]="box.toBottom"
  >
  </app-box>
</ng-container>

<section id="footer">
  <div class="footer-top">
      <div class="container">
          <small>A Glutabalance Psorio Forte és a Glutabalance Psorio Caps étrend-kiegészítő termékek.<br>Étrend-kiegészítő, nem helyettesíti a vegyes étrendet és az egészséges életmódot.</small>
          <div class="action-box">
              <h3>Kipróbálom a Glutabalance termékeit!</h3>
              <a [routerLink]="['/vasarlasi-informaciok']" routerLinkActive="router-link-active" >Tovább <i class="icon-chevron-right" aria-hidden="true"></i></a>
          </div>
      </div>
  </div>
</section>