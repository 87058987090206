import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import testimonial from "../../..//assets/json/testimonial.json";

@Component({
  selector: "app-testimonial",
  templateUrl: "./testimonial.component.html",
  styleUrls: ["./testimonial.component.scss"],
})
export class TestimonialComponent implements OnInit {
  testimonials = testimonial;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
  ) {
    console.log(this.testimonials);
  }

  ngOnInit(): void {
    this.titleService.setTitle("Vélemények - Glutabalance");
    this.metaTagService.updateTag({ name: "description", content: "A Glutabalance termékeinek hatóanyagai hozzájárulnak a száraz, hámló bőr tüneteinek segítségében. Tudjon meg többet a pikkelysömör tüneteiről is." });
  }
}
