import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../models/user.model';
import { map, take } from 'rxjs/operators';
import { CodesService } from './codes.service';
import { Code } from '../models/code.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TermekkiprobalasService {

  usersCollectionName: string;
  codesCollectionName: string;

  constructor(
    private firestore: AngularFirestore,
    private codesService: CodesService,
    private http: HttpClient
  ) {
    
    this.usersCollectionName = 'mini-research-users';
    this.codesCollectionName = 'mini-research-codes';
  }

  createUser(user: User) {
    return this.http.get('https://us-central1-glutabalance.cloudfunctions.net/createUser', {
      params: {
        name: user.name,
        email: user.email,
        telephone: user.telephone,
        address: user.address
      },
      observe: 'response'
    })
    .toPromise();  
  }

  validateConfigPsw() {
    return this.firestore.collection('config').doc('psw').snapshotChanges().pipe(map(a => {
      const data = a.payload.data() as any;
      return data;
    }))
  }



  // createUser(user: User, codes: Code[]) {
  //   let code = codes.find(code => !code.isUsed);
  //   code.isUsed = true;
  //   user.code = code.code;
  //   this.codesService.updateCode(code);
  //   return this.firestore.collection(this.usersCollectionName).add(user);
  // }

  // getUsers() {
  //   return this.firestore.collection(this.usersCollectionName).snapshotChanges().pipe(map(changes => {
  //     return changes.map(a => {
  //       const data = a.payload.doc.data() as User;
  //       data.id = a.payload.doc.id;
  //       return data;
  //     }).sort((a: User, b: User) => {
  //       const date1 = new Date(a.registrationDate);
  //       const date2 = new Date(b.registrationDate);
  //       return date2.getTime() - date1.getTime();
  //     });
  //   }));
  // }

  // getUser(id: string) {
  //   return this.firestore.collection(this.usersCollectionName).doc(id).snapshotChanges().pipe(map(a => {
  //     const data = a.payload.data() as User;
  //     data.id = a.payload.id;
  //     return data;
  //   }));
  // }

}
