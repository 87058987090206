<section id="footer">
  <div class="wave"></div>
  <footer>
    <div class="container">
      <div class="logo-box">
        <img src="/assets/logo-white.webp" width="100%" alt="" />
      </div>
      <div class="contact-box">
        <div class="contact">
          <h6>Kapcsolat:</h6>
          <p><strong>Tel.:</strong> (+36) 1 919 3354</p>
          <p><strong>Fax.:</strong> (+36) 1 919 0354</p>
          <p><strong>E-mail:</strong> info@glutabalance.hu</p>
          <p>
            <strong>Cím:</strong> PP Management Kft.<br />1052 Budapest, Petőfi
            S. u. 11. 4/20.
          </p>
          <div class="line"></div>
          <a
            href="/assets/adatkezelesi-tajekoztato.pdf"
            target="_blank"
            style="text-decoration: underline"
            >Adatkezelési tájékoztató</a
          >
        </div>
      </div>
      <div class="sitemap-box">
        <div class="sitemap">
          <h6>Oldaltérkép:</h6>
          <p>
            <a [routerLink]="['glutabalance-psorio-forte']"
              >Glutabalance Psorio Forte</a
            >
          </p>
          <p>
            <a [routerLink]="['glutabalance-psorio-caps']"
              >Glutabalance Psorio Caps</a
            >
          </p>
          <p>
            <a [routerLink]="['velemenyek']">Vélemények</a>
          </p>
          <p>
            <a [routerLink]="['szaraz-gyulladt-viszketo-bor']"
              >Száraz, gyulladt, viszkető bőr?</a
            >
          </p>
          <p>
            <a [routerLink]="['mi-az-ekcema']">Mi az ekcéma?</a>
          </p>
          <p>
            <a [routerLink]="['mi-a-pikkelysomor']">Mi a pikkelysömör?</a>
          </p>
          <p>
            <a [routerLink]="['a-glutabalance-hatasai']"
              >Hogyan hatnak a Glutabalance termékei?</a
            >
          </p>
          <p>
            <a [routerLink]="['liposzomalis-technologia']"
              >Liposzómás technológia</a
            >
          </p>
          <p>
            <a [routerLink]="['vasarlasi-informaciok']"
              >Vásárlási információk</a
            >
          </p>
          <p>
            <a [routerLink]="['kapcsolat']">Kapcsolat</a>
          </p>
        </div>
      </div>
    </div>
    <div class="reg">
      <span>
        &copy; Copyright - Glutabalance 2021 - {{ getCurrentYear() }}
      </span>
      <div class="sitepro">
        <a
          href="https://www.sitepro.hu/?utm_source=glutabalance&utm_medium=reference_website"
          target="_blank"
          ><img src="/assets/sitepro-white-2.svg" alt="" class="sitepro-logo"
        /></a>
      </div>
    </div>
  </footer>
</section>
