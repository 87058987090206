import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-termekek-tab',
  templateUrl: './termekek-tab.component.html',
  styleUrls: ['./termekek-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermekekTabComponent implements OnInit {

  @Input() title;
  @Input() subtitle;
  @Input() table;
  @Input() ingredients;
  @Input() dosage;
  @Input() storage;
  @Input() feedback;

  constructor() { }

  ngOnInit(): void {
  }

}
