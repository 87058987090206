<app-hero
  [bgUrl]="'/assets/mi-az-ekcema/header-2.webp'"
  [bgUrlM]="'/assets/mi-az-ekcema/header-m-2.webp'"
  [header]="'Mi az atópiás dermatitisz vagy ekcéma?'"
  [text]="
    'Az atópiás dermatitisz kialakulásában genetikai és környezeti tényezők játszanak szerepet.'
  "
></app-hero>
<section id="mi-az-ekcema">
  <div class="container">
    <div class="def-box">
      <h3>Atópiás dermanitisz</h3>
      <p>
        <strong>Az atópiás dermatitisz vagy ekcéma</strong> egy krónikus
        lefolyású, folyamatos fellángolásokkal járó, nem fertőző, gyulladásos
        bőrbetegség, amely jelentősen rontja az életminőséget <sup>(1)</sup>.
      </p>
    </div>
    <div class="d-flex elofordulas">
      <div class="text">
        <h2>Előfordulás</h2>
        <p>
          Elsősorban a <strong>gyermekeket érinti</strong>, de a felnőtteknél is
          előfordul <sup>(1)</sup>.
        </p>
        <p>
          Az atópiás dermatitisz Magyarországon
          <strong>a gyermekek 17 %-át érinti</strong>, és a
          <strong>felnőttek 2-10 %-ánál</strong> fordulhat elő
          <sup>(1, 2)</sup>.
        </p>
        <p>
          Az ekcémás megbetegedések 80 %-ában a kezdet az első 5 életévre tehető
          <sup>(1)</sup>.
        </p>
        <p>
          <strong
            >A betegek 60-70 %-a 20 éves korra „kinövi” a betegséget</strong
          >
          <sup>(1)</sup>.<br />A gyermekkorban induló és felnőttkorra is
          „megmaradó” formára hajlamosít
        </p>
        <ul>
          <li>
            <strong
              >pozitív családtörténet (közeli rokonoknál előforduló
              ekcéma),</strong
            >
          </li>
          <li><strong>korai betegségkezdet,</strong></li>
          <li><strong>súlyos gyermekkori klinikai forma,</strong></li>
          <li>
            <strong>fényérzékeny, világos bőrtípus <sup>(1)</sup>.</strong>
          </li>
        </ul>
      </div>
      <div class="img">
        <img src="/assets/mi-az-ekcema/elofordulas-3.webp" alt="" />
      </div>
    </div>
    <h2>Az ekcéma vezető tünetei</h2>
    <div class="symptoms">
      <div class="d-flex">
        <div class="symptom-box dirty">
          <div class="gradient">
            <span>bőr szárazsága</span>
          </div>
        </div>
        <div class="symptom-box itch">
          <div class="gradient">
            <span>viszketés</span>
          </div>
        </div>
      </div>
    </div>
    <h2>Szakaszai</h2>
    <div class="sections">
      <div class="d-flex">
        <div class="section-box section-1">
          <div class="gradient">
            <span
              >Hullámzó lefolyás, fellángolásokkal<br />(= akut
              szakaszokkal)</span
            >
          </div>
        </div>
        <div class="section-box section-2">
          <div class="gradient">
            <span
              >Akut szakaszban váladékozó, duzzadt, pirosas foltokkal és
              plakkokkal teli lehet az érintett bőrterület</span
            >
          </div>
        </div>
        <div class="section-box section-3">
          <div class="gradient">
            <span
              >Krónikus stádiumban száraz, szarusodó, hámló plakkok
              jellemzőek</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex lokalizacio">
      <div class="img">
        <img src="/assets/mi-az-ekcema/lokalizacio.webp" alt="" />
      </div>
      <div class="text">
        <h2 class="m-0">Lokalizáció</h2>
        <h3>Életkortól függően</h3>
        <p>
          Az első tünetek csecsemőkorban, <strong>2-3 hónaposan</strong>,
          kezdődhetnek. Csecsemőkön jellemzően a
          <strong>hajas fejbőr és a homlok, az arc érintett</strong>
          <sup>(1, 3)</sup>.
        </p>
        <p>
          A gyermekkori formánál a végtagok és a törzs is érintettek. Tipikus
          megjelenési helyek a hajlító felszínek: könyökhajlat, kéztő,
          térdhajlat, boka, illetve a tarkó, a talp, a kezek <sup>(1)</sup>.
        </p>
        <p>
          Felnőtt korban a gyermekkorinál általában enyhébbek a tünetek, amik a
          hajlatokat, a nyakat, arcot, és a kéz és a láb területét érintik
          <sup>(1)</sup>.
        </p>
      </div>
    </div>
    <div class="reasons">
      <h3>Oki tényezők, lefolyás</h3>
      <div class="d-flex">
        <div class="text">
          <p>
            Az atópiás dermatitisz kialakulásában genetikai és környezeti
            tényezők játszanak szerepet <sup>(1)</sup>.
          </p>
          <p>
            A genetikai meghatározottság mellett az ekcéma kialakulásában és a
            fellángolások létrejöttében lényegesek a környezeti faktorok, így...
          </p>
        </div>
        <div class="list">
          <p><strong>A,</strong><span> mint </span>Allergének</p>
          <p><strong>B,</strong><span> mint </span>Baktériumok, vírusok</p>
          <p>
            <strong>C,</strong><span> mint </span>chemicals, climate -
            Kemikáliák, éghajlat
          </p>
          <p>
            <strong>D,</strong><span> mint </span>dry skin, drugs - Száraz bőr,
            gyógyszerek
          </p>
          <p>
            <strong>+1,</strong><span>&nbsp;</span>Pszichés tényezők,
            stresszhelyzetek
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex comorbidity-quality-of-life">
      <div class="comorbidity">
        <h3>Társbetegségek <sup>(1, 4)</sup></h3>
        <div class="d-flex">
          <div class="line">
            <img src="/assets/mi-az-ekcema/voros-bor.webp" alt="" />
            <p>
              <strong>Bőrfertőzések:</strong> baktérium-, vírus- vagy
              gomba-okozta bőrfertőzések
            </p>
          </div>
          <div class="line">
            <img src="/assets/mi-az-ekcema/asztma.webp" alt="" />
            <p>
              <strong>2-es típusú gyulladással járó betegségek:</strong> asztma,
              szénanátha, ételallergia, orrpolip, allergiás nyelőcsőgyulladás
            </p>
          </div>
          <div class="line">
            <img src="/assets/mi-az-ekcema/kopaszodas.webp" alt="" />
            <p>
              <strong>Immunmediált betegségek:</strong> foltos kopaszodás,
              foltos festékhiány (=vitiligo)
            </p>
          </div>
          <div class="line">
            <img src="/assets/mi-az-ekcema/depresszio.webp" alt="" />
            <p>
              <strong>Pszichés betegségek:</strong> depresszió, szorongás,
              figyelemhiányos hiperaktivitás-zavar (ADHD)
            </p>
          </div>
        </div>
      </div>
      <div class="quality-of-life">
        <h3>Életminőség</h3>
        <div class="d-flex">
          <div class="line">
            <img src="/assets/mi-az-ekcema/faradt.webp" alt="" />
            <p>
              A <strong>viszketés zavarja az alvás nyugalmát</strong>, az
              ekcémás gyermekek <strong>60-83 %-ánál alvászavar</strong> lép fel
              <sup>(5)</sup>.
            </p>
          </div>
          <div class="line">
            <img src="/assets/mi-az-ekcema/depresszio.webp" alt="" />
            <p>
              <strong>Fizikai és emocionális fáradtság, depresszióra,</strong>
              szorongásra, öngyilkossági gondolatokra való fokozott hajlam,
              <strong>társasági élet zavarai</strong><sup>(5)</sup>.
            </p>
          </div>
          <div class="line">
            <img src="/assets/mi-az-ekcema/szteroid.webp" alt="" />
            <p>
              <strong
                >Félelem attól, hogy szteroidos készítmények alkalmazására fog
                szorulni a beteg</strong
              ><sup>(5)</sup>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="footer">
  <div class="footer-top">
    <div class="container">
      <small><strong>Forrás</strong></small>
      <small
        >(1) Gáspár K. Nagy kihívás az „atópiás menetelés” megállítása;
        www.pharmaonline.hu ; 2022-04-02</small
      >
      <small
        >(2) Kuhnyar A, Egyud K, Szabo I, et al. Prevalence of atopic dermatitis
        among children under 19 in an East-Hungarian agricultural county. Clin
        Dev Immunol 2006;13:395–9.</small
      >
      <small
        >(3) Az Egészségügyi Minisztérium szakmai protokollja; Atópiás
        Dermatitis (L2090)</small
      >
      <small
        >(4) Paller A, Jaworski JC, Simpson EL, Boguniewicz M, Russell JJ, Block
        JK et al. Major Comorbidities of Atopic Dermatitis: Beyond Allergic
        Disorders. Am J Clin Dermatol. 2018;19:821-838.</small
      >
      <small
        >(5) Na CH, Chung J, Simpson EL. Quality of Life and Disease Impact of
        Atopic Dermatitis and Psoriasis on Children and Their Families. Children
        (Basel). 2019;6(12):133. Published 2019 Dec 2.
        doi:10.3390/children6120133</small
      >

      <small
        >A Glutabalance Psorio Forte és a Glutabalance Psorio Caps
        étrend-kiegészítő termékek.<br />Étrend-kiegészítő, nem helyettesíti a
        vegyes étrendet és az egészséges életmódot.</small
      >
      <div class="action-box">
        <h3>Kipróbálom a Glutabalance termékeit!</h3>
        <a
          [routerLink]="['/vasarlasi-informaciok']"
          routerLinkActive="router-link-active"
          >Tovább <i class="icon-chevron-right" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</section>
