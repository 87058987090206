import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-fooldal",
  templateUrl: "./fooldal.component.html",
  styleUrls: ["./fooldal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooldalComponent implements OnInit {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit(): void {
    this.titleService.setTitle("Főoldal - Glutabalance");
    this.metaTagService.updateTag({
      name: "description",
      content:
        "A Glutabalance termékeinek hatóanyagai hozzájárulnak a száraz, hámló bőr tüneteinek segítségében. Tudjon meg többet a pikkelysömör tüneteiről is.",
    });
  }
}
