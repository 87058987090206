<section id="box">
    <div class="container">
        <div class="box" [class.toBottomLeft]="toBottom && direction === '2'" [class.toBottomRight]="toBottom && direction === '1'">
            <div class="text" [style.order]="direction" [ngStyle]="{'text-align': direction === '1' ? 'left' : 'right'}">
                <h2>{{ header }}</h2>
                <div class="text-box" [innerHTML]="text"></div>
            </div>
            <div class="img">
                <img [src]="imgUrl" alt="">
            </div>
        </div>
    </div>
</section>